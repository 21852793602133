import {
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode
} from 'react';

export default function MobileContentContainer(
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) {
  return (
    <div
      {...props}
      style={{
        minHeight: 'calc(100dvh - 116px)',
        background: '#FAFAFAFA',
        ...props.style
      }}
    />
  );
}
