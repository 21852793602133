import { Button, Result } from 'core-ui';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    // <Result
    //   status="404"
    //   title="404"
    //   subTitle="アクセスしたページは存在しません."
    //   extra={
    //     <Button type="primary" onClick={() => navigate(-1)}>
    //       キャンセル
    //     </Button>
    //   }
    // />
    <div>アクセスしたページは存在しません.</div>
  );
}
