import { Flex } from 'core-ui';
import NotFound from 'src/components/not-found';

export default function NotFoundPage() {
  return (
    <Flex
      align="center"
      justify="center"
      style={{
        height: '100dvh',
        width: '100dvw',
        zIndex: 100,
        position: 'fixed',
        top: 0,
        left: 0,
        background: 'var(--bg)'
      }}
    >
      <NotFound />
    </Flex>
  );
}
