import { App, Button, Flex, Form, Input, Typography } from 'core-ui';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { apiRequest } from 'src/utils/api';

export default function LoginPage() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    try {
      const { data } = await apiRequest.post({
        url: '/v1/auth/login',
        data: form.getFieldsValue()
      });
      Cookies.set('access-token', data.data.accessToken);
      navigate('/');
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
  };
  return (
    <div style={{ height: '100dvh' }}>
      <img
        alt="login-bg"
        src="login-background.png"
        style={{ height: '25%', objectFit: 'cover', width: '100%' }}
      />
      <div
        style={{
          height: '72%',
          padding: '40px 16px',
          position: 'relative',
          bottom: '3%',
          background: 'white',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16
        }}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: 32,
            textAlign: 'center',
            marginBottom: 24
          }}
        >
          レシート管理システム
        </div>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            name="email"
            label="メールアドレス"
            rules={[{ required: true, type: 'email' }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="password"
            label="パスワード"
            rules={[{ required: true }]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Flex justify="end" style={{ paddingBottom: 16 }}>
            <Link to="/forgot-password">
              <Typography.Link>パスワードを忘れた場合</Typography.Link>
            </Link>
          </Flex>
          <Button
            onClick={form.submit}
            type="primary"
            size="large"
            block
            style={{ marginBottom: 16 }}
          >
            ログイン
          </Button>
          <Button
            onClick={() => navigate('/register')}
            type="primary"
            ghost
            size="large"
            block
            style={{ marginBottom: 16 }}
          >
            新規登録
          </Button>
        </Form>
      </div>
    </div>
  );
}
