import { ArrowLeftOutlined, BackwardOutlined, Button, Flex } from 'core-ui';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import LeftArrowIcon from 'src/assets/icons/left-arrow';
import { systemColor } from 'src/theme';

interface I_Props {
  title?: string;
  goBack?: string | boolean;
  rightButton?: ReactNode;
}
export default function MobileHeader(props: I_Props) {
  const navigate = useNavigate();
  return (
    <Flex
      align="center"
      justify="center"
      style={{
        height: 56,
        background: systemColor.primary,
        position: 'sticky',
        top: 0,
        color: 'white',
        zIndex: 10
      }}
    >
      <span
        hidden={!props.goBack}
        style={{ position: 'absolute', left: 10, top: 11 }}
      >
        <Button
          type="text"
          shape="circle"
          style={{ color: 'white', fontSize: 18 }}
          onClick={() =>
            props.goBack === true
              ? navigate(-1)
              : navigate(props.goBack as string)
          }
        >
          <LeftArrowIcon />
        </Button>
      </span>
      <span style={{ fontWeight: 600 }}>{props.title}</span>
      <span
        hidden={!props.goBack}
        style={{ position: 'absolute', right: 10, top: 11 }}
      >
        {props.rightButton}
      </span>
    </Flex>
  );
}
