/* eslint-disable no-unused-vars */
export const API_URL = process.env.REACT_APP_API_URL || '';

export enum ApiPath {
  USER = '/api/v1/users',
  LOGIN = '/api/v1/login',
  REGISTER = '/api/v1/register',
  TODO = '/api/v1/todos',
  RESET_PASSWORD = '/api/v1/reset_password',
  UPDATE_PASSWORD = '/api/v1/update_password'
}
