export default function Download() {
  return (
    <svg
      width={22}
      height={20}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8296 13.9861C10.8496 14.0117 10.8752 14.0324 10.9045 14.0466C10.9337 14.0609 10.9658 14.0683 10.9983 14.0683C11.0308 14.0683 11.0629 14.0609 11.0922 14.0466C11.1214 14.0324 11.147 14.0117 11.1671 13.9861L14.1671 10.1905C14.2769 10.0512 14.1778 9.84498 13.9983 9.84498H12.0135V0.780692C12.0135 0.662835 11.9171 0.566406 11.7992 0.566406H10.1921C10.0742 0.566406 9.97779 0.662835 9.97779 0.780692V9.8423H7.99833C7.81886 9.8423 7.71975 10.0486 7.82958 10.1878L10.8296 13.9861ZM20.8019 13.0486H19.1948C19.0769 13.0486 18.9805 13.145 18.9805 13.2628V17.3878H3.01618V13.2628C3.01618 13.145 2.91975 13.0486 2.8019 13.0486H1.19475C1.0769 13.0486 0.980469 13.145 0.980469 13.2628V18.5664C0.980469 19.0405 1.3635 19.4236 1.83761 19.4236H20.159C20.6331 19.4236 21.0162 19.0405 21.0162 18.5664V13.2628C21.0162 13.145 20.9198 13.0486 20.8019 13.0486Z"
        fill="white"
      />
    </svg>
  );
}
