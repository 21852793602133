import { Flex } from 'core-ui';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { systemColor } from 'src/theme';

interface I_Props {
  icon: ReactNode;
  title: string;
  path: string;
  extraAction?: () => void;
}
export default function NavigationButton(props: I_Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.pathname === props.path;
  return (
    <Flex
      align="center"
      justify="center"
      vertical
      onClick={() => {
        navigate(props.path);
        props.extraAction?.();
      }}
      style={{
        width: '100%',
        color: isActive ? systemColor.primary : systemColor.textSecondary
      }}
    >
      <div
        style={{
          borderRadius: 13,
          width: 46,
          textAlign: 'center',
          background: isActive ? '#E6F7FF' : ''
        }}
      >
        {props.icon}
      </div>
      <div>{props.title}</div>
    </Flex>
  );
}
