import { ThemeConfig } from 'core-ui';

export const systemColor = {
  primary: '#1890ff',
  semiPrimary: '#91d5ff',
  textPrimary: '#303030',
  textSecondary: '#656565',
  link: '#1890ff'
};

const theme: ThemeConfig = {
  token: {
    colorPrimary: systemColor.primary,
    colorLink: systemColor.link,
    borderRadius: 2,
    colorText: systemColor.textPrimary
  },
  components: {
    Form: {
      labelHeight: 22
    },
    Button: {
      borderRadiusLG: 8
    },
    Select: {},
    Input: {},
    Table: {},
    Menu: {
      // itemMarginInline: 0,
      // itemMarginBlock: 0,
      // itemBorderRadius: 0,
      // itemSelectedColor: systemColor.blue,
      // itemSelectedBg: systemColor.blueLight,
      // activeBarWidth: 3,
      // itemActiveBg: systemColor.blueLight,
      // itemHeight: 40,
      // iconSize: 18,
      // itemColor: systemColor.black,
      // colorItemBgSelected: systemColor.blueLight
    }
  }
};
export default theme;
