export default function Logout() {
  return (
    <svg
      width={23}
      height={23}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.28516 7.42875C8.56932 4.12875 10.2652 2.78125 13.9777 2.78125H14.0968C18.1943 2.78125 19.8352 4.42208 19.8352 8.51958V14.4963C19.8352 18.5938 18.1943 20.2346 14.0968 20.2346H13.9777C10.2927 20.2346 8.59682 18.9054 8.29432 15.6604"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.95703 11.5H13.7637"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7227 8.42969L14.7935 11.5005L11.7227 14.5714"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
