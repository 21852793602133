import {
  App,
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  PhoneInput,
  Typography
} from 'core-ui';
import { Link, useNavigate } from 'react-router-dom';
import LongLeftArrowIcon from 'src/assets/icons/long-left-arrow';
import LongLeftArrow from 'src/assets/icons/long-left-arrow/long-left-arrow';
import { DATE_FORMAT } from 'src/constants/date';
import { notifyMessage } from 'src/constants/message';
import { RoutePath } from 'src/enums/routePath';
import { apiRequest } from 'src/utils/api';
import { formDatePickerProps } from 'src/utils/form-props';

export default function RegisterPage() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    try {
      await apiRequest.post({
        url: '/v1/auth/register',
        data: form.getFieldsValue()
      });
      navigate(RoutePath.LOGIN);
      message.success(notifyMessage.success);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
  };
  return (
    <div style={{ padding: '32px 16px' }}>
      <div
        style={{
          fontWeight: 600,
          fontSize: 32,
          textAlign: 'center',
          marginBottom: 24
        }}
      >
        登録する
      </div>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="email"
          label="メールアドレス"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input placeholder="メールアドレス" size="large" />
        </Form.Item>
        <Form.Item
          name="password"
          label="パスワード"
          rules={[{ required: true }]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder="********"
            size="large"
          />
        </Form.Item>
        <Form.Item name="name" label="名前" rules={[{ required: true }]}>
          <Input placeholder="名前" size="large" />
        </Form.Item>
        <Form.Item name="dateOfBirth" label="誕生日" {...formDatePickerProps}>
          <DatePicker
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            size="large"
          />
        </Form.Item>
        <Form.Item name="phoneNumber" label="電話番号">
          <PhoneInput placeholder="電話番号" size="large" />
        </Form.Item>
        <Form.Item name="address" label="住所">
          <Input placeholder="住所" size="large" />
        </Form.Item>

        <Button
          htmlType="submit"
          type="primary"
          size="large"
          block
          style={{ margin: '16px 0' }}
        >
          登録する
        </Button>
        <Link to="/login">
          <Button block type="link" icon={<LongLeftArrowIcon />}>
            ログイン画面へ戻る
          </Button>
        </Link>
      </Form>
    </div>
  );
}
