export default function Note() {
  return (
    <svg
      width={23}
      height={23}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9596 8.0612V16.9987C18.9596 19.7487 17.3188 20.6654 15.293 20.6654H7.95964C5.9338 20.6654 4.29297 19.7487 4.29297 16.9987V8.0612C4.29297 5.08203 5.9338 4.39453 7.95964 4.39453C7.95964 4.96286 8.18878 5.47619 8.56461 5.85203C8.94044 6.22786 9.4538 6.45703 10.0221 6.45703H13.2305C14.3671 6.45703 15.293 5.5312 15.293 4.39453C17.3188 4.39453 18.9596 5.08203 18.9596 8.0612Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2904 4.39453C15.2904 5.5312 14.3645 6.45703 13.2279 6.45703H10.0195C9.4512 6.45703 8.93784 6.22786 8.56201 5.85203C8.18617 5.47619 7.95703 4.96286 7.95703 4.39453C7.95703 3.25786 8.88286 2.33203 10.0195 2.33203H13.2279C13.7962 2.33203 14.3096 2.5612 14.6854 2.93703C15.0612 3.31287 15.2904 3.8262 15.2904 4.39453Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.95703 12.418H11.6237"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.95703 16.082H15.2904"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
