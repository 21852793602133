import { Flex, QRCode } from 'core-ui';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileContentContainer from 'src/components/mobile-content-container';
import MobileFooterContainer from 'src/components/mobile-footer-container';
import MobileFooterNavigation from 'src/components/mobile-footer-navigation';
import MobileHeader from 'src/components/mobile-header';
import ReceiptItem from 'src/components/receipt-item';
import { ProfileContext } from 'src/contexts/profile';
import { I_Receipt } from 'src/models';
import { systemColor } from 'src/theme';
import { apiRequest } from 'src/utils/api';

export default function IndexPage() {
  const { profile } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<I_Receipt[]>([]);
  const fetchData = async () => {
    const { data } = await apiRequest.get({
      url: '/v1/receipts'
    });
    setDataSource(data.data.filter((i: I_Receipt, idx: number) => idx < 3));
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <MobileHeader title="マイページ" />
      <MobileContentContainer>
        <div style={{ padding: '0 0.5rem 1rem 0.5rem' }}>
          <Flex
            justify="center"
            align="center"
            style={{ padding: '24px 0 30px' }}
          >
            <img src="qr.png" alt="qr" style={{ height: 300, width: 290 }} />
            <div
              style={{
                position: 'absolute',
                background: 'white',
                marginTop: 10
              }}
            >
              <QRCode value={profile.id} size={170} />
            </div>
          </Flex>
          <div
            style={{
              border: `1px solid ${systemColor.semiPrimary}`,
              borderRadius: 12,
              position: 'relative',
              background: 'white',
              padding: '26px 8px'
            }}
          >
            <span
              style={{
                position: 'absolute',
                top: -13,
                left: 8,
                padding: '0 4px',
                background: 'white',
                fontWeight: 600,
                color: systemColor.textPrimary
              }}
            >
              最近のレシート
            </span>
            <span
              style={{
                position: 'absolute',
                top: -13,
                right: 12,
                padding: '0 4px',
                background: 'white',
                color: systemColor.primary
              }}
              onClick={() => navigate('/receipts')}
            >
              全て
            </span>
            <Flex vertical gap={8}>
              {dataSource.map((i, idx) => (
                <ReceiptItem key={idx} data={i} />
              ))}
            </Flex>
          </div>
        </div>
      </MobileContentContainer>
      <MobileFooterContainer>
        <MobileFooterNavigation />
      </MobileFooterContainer>
    </div>
  );
}
