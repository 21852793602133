import { dayjs } from 'core-helpers';
import { FormItemProps } from 'core-ui';
import { DATE_FORMAT } from 'src/constants/date';

export const formDatePickerProps: FormItemProps = {
  getValueProps: (value) => value && { value: dayjs(value) }, // format value from form data to value that can use by Antd datepicker
  normalize: (value) => value && dayjs(value).format(DATE_FORMAT) // format data before passing to form data
};
export const formNumericFormatProps: FormItemProps = {
  getValueFromEvent: (e) => e.value,
  trigger: 'onValueChange'
};
