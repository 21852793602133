import { dayjs } from 'core-helpers';
import { Flex } from 'core-ui';
import { useNavigate } from 'react-router-dom';
import CalendarIcon from 'src/assets/icons/calendar';
import { I_Receipt } from 'src/models';
import { systemColor } from 'src/theme';

interface I_Props {
  data: I_Receipt;
}

export default function ReceiptItem(props: I_Props) {
  const navigate = useNavigate();
  return (
    <Flex
      gap={8}
      style={{
        padding: '8px 12px',
        border: '1px solid #F0F0F0',
        borderRadius: 8,
        height: 65
      }}
      onClick={() => navigate(`/receipts/${props.data.id}`)}
    >
      <div
        style={{
          width: 6.5,
          background: systemColor.primary,
          borderEndEndRadius: 3.5,
          borderStartEndRadius: 3.5
        }}
      />
      <Flex gap={8} style={{ flex: 1 }} vertical justify="center">
        <div style={{ fontSize: 14, color: systemColor.textPrimary }}>
          店舗名
        </div>
        <Flex>
          <CalendarIcon style={{ color: '#3564DB', marginRight: 8 }} />
          <span style={{ fontSize: 12, color: systemColor.textSecondary }}>
            {dayjs(props.data.purchaseDate).format('YYYY/MM/DD HH:mm')}
          </span>
        </Flex>
      </Flex>
      <div style={{ fontWeight: 600, color: systemColor.textPrimary }}>
        ¥{props.data.totalPayment.toLocaleString()}
      </div>
    </Flex>
  );
}
