import {
  ArrowLeftOutlined,
  BackwardOutlined,
  Button,
  Flex,
  HomeOutlined
} from 'core-ui';
import { useNavigate } from 'react-router-dom';
import NavigationButton from './components/navigation-button';
import HomeIcon from 'src/assets/icons/home';
import NoteIcon from 'src/assets/icons/note';
import UserSettingIcon from 'src/assets/icons/user-setting';
import LogoutIcon from 'src/assets/icons/logout';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_KEY } from 'src/constants/auth';

interface I_Props {
  title?: string;
  goBack?: string | boolean;
}
export default function MobileFooterNavigation(props: I_Props) {
  const navigate = useNavigate();
  return (
    <Flex
      align="center"
      justify="space-around"
      style={{ padding: '12px 8px 8px', fontSize: '0.75rem' }}
    >
      <NavigationButton path="/" icon={<HomeIcon />} title="ホーム" />
      <NavigationButton
        path="/receipts"
        icon={<NoteIcon />}
        title="レシート履歴"
      />
      <NavigationButton
        path="/profile"
        icon={<UserSettingIcon />}
        title="設定"
      />
      <NavigationButton
        extraAction={() => Cookies.remove(ACCESS_TOKEN_KEY)}
        path="/login"
        icon={<LogoutIcon />}
        title="ログアウト"
      />
    </Flex>
  );
}
