import { dayjs } from 'core-helpers';
import { App, Button, DownloadOutlined, Flex } from 'core-ui';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CalendarIcon from 'src/assets/icons/calendar';
import DownloadIcon from 'src/assets/icons/download';
import MobileContentContainer from 'src/components/mobile-content-container';
import MobileFooterContainer from 'src/components/mobile-footer-container';
import MobileHeader from 'src/components/mobile-header';
import { I_Receipt } from 'src/models';
import { systemColor } from 'src/theme';
import { apiRequest } from 'src/utils/api';

export default function Invoice() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [dataSource, setDataSource] = useState<I_Receipt>();
  const fetchData = async () => {
    const { data } = await apiRequest.get({
      url: `/v1/receipts/${id}`
    });
    setDataSource(data.data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  if (dataSource)
    return (
      <div>
        <MobileHeader
          title="レシート"
          goBack
          rightButton={
            <Button
              onClick={() => message.success('レシートが保存されました。')}
              shape="circle"
              type="text"
              style={{ color: 'white', fontSize: 18 }}
            >
              <DownloadIcon />
            </Button>
          }
        />
        <MobileContentContainer>
          <div style={{ padding: '1rem' }}>
            <Flex
              style={{
                background: 'white',
                padding: 12,
                border: '1px solid #F0F0F0',
                borderRadius: 8,
                marginBottom: 12
              }}
            >
              <Flex gap={10} vertical style={{ flex: 1 }}>
                <div style={{ fontSize: 14, color: systemColor.textPrimary }}>
                  店舗名
                </div>
                <div>
                  <CalendarIcon style={{ marginRight: 8, color: '#3564DB' }} />
                  <span style={{ fontSize: 12 }}>
                    {dayjs(dataSource.purchaseDate).format('YYYY/MM/DD (ddd)')}
                  </span>
                </div>
              </Flex>
              <div style={{ fontWeight: 600 }}>
                ¥{dataSource.totalPayment.toLocaleString()}
              </div>
            </Flex>
            <div
              style={{
                padding: '1rem 10px',
                background: 'white',
                border: '1px solid #00000026',
                borderRadius: 8,
                fontSize: 14
              }}
            >
              <img src="/logo-name.png" alt="logo" />
              <div style={{ margin: '0.5rem 0 1rem' }}>
                福島県白河市新蔵町632-4
                <br />
                <span style={{ color: systemColor.textSecondary }}>電話：</span>
                03-0000-0000
              </div>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 18,
                  textAlign: 'center',
                  marginBottom: '1rem'
                }}
              >
                領 収 書
              </div>
              <div style={{ color: systemColor.textSecondary }}>
                {dayjs(dataSource.purchaseDate).format(
                  'YYYY年MM月DD日 (ddd) HH:mm'
                )}
              </div>
              <Flex gap={12} vertical>
                {dataSource.products.map((i) => (
                  <Flex gap={20} key={i.id}>
                    <span
                      style={{
                        color: systemColor.textSecondary,
                        wordBreak: 'break-all'
                      }}
                    >
                      {i.name}
                    </span>
                    <span style={{ flex: 1 }}>x{i.quantity}</span>
                    <span>¥{i.price.toLocaleString()}</span>
                  </Flex>
                ))}
              </Flex>
            </div>
          </div>
        </MobileContentContainer>
        <MobileFooterContainer>
          <div style={{ padding: '0.5rem 1rem', fontSize: 14 }}>
            <div
              style={{
                background: '#F9F9F9',
                borderRadius: 2,
                padding: '8px 5px',
                marginBottom: 12
              }}
            >
              <Flex justify="space-between">
                <span style={{ color: systemColor.textSecondary }}>小計</span>
                <span style={{ fontWeight: 600 }}>
                  ¥{dataSource.totalAmount.toLocaleString()}
                </span>
              </Flex>
              <Flex justify="space-between">
                <span style={{ color: systemColor.textSecondary }}>割引 </span>
                <span style={{ fontWeight: 600 }}>-¥0</span>
              </Flex>
              <Flex justify="space-between">
                <span style={{ color: systemColor.textSecondary }}>
                  消費税 ({dataSource.tax}%)
                </span>
                <span style={{ fontWeight: 600 }}>
                  ¥{dataSource.taxAmount.toLocaleString()}
                </span>
              </Flex>
              <Flex justify="space-between" style={{ fontSize: 18 }}>
                <span style={{ fontWeight: 600 }}>合計</span>
                <span style={{ fontWeight: 600 }}>
                  ¥{dataSource.totalPayment.toLocaleString()}
                </span>
              </Flex>
            </div>
            <Button block size="large" onClick={() => navigate(-1)}>
              戻る
            </Button>
          </div>
        </MobileFooterContainer>
      </div>
    );
  return <></>;
}
