export default function Home() {
  return (
    <svg
      width={23}
      height={23}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.14203 3.10169L4.2012 6.95169C3.3762 7.59336 2.70703 8.95919 2.70703 9.99502V16.7875C2.70703 18.9142 4.43953 20.6559 6.5662 20.6559H17.1812C19.3079 20.6559 21.0404 18.9142 21.0404 16.7967V10.1234C21.0404 9.01419 20.2979 7.59336 19.3904 6.96086L13.7254 2.99169C12.442 2.09336 10.3795 2.13919 9.14203 3.10169Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 16.9922V14.2422"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
