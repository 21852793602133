import { BrowserRouter } from 'react-router-dom';
import AppRouter from './router';
import { Provider } from 'react-redux';
import ModalProvider from './contexts/modalContext';
import DrawerProvider from './contexts/drawerContext';
import { store } from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider, ja_JP, App as AppProvider } from 'core-ui';
import theme from './theme';
import './App.scss';
import { dayjs } from 'core-helpers';
import ProfileProvider from './contexts/profile';
const queryClient = new QueryClient();

dayjs.locale('ja-JP');

const App = () => {
  return (
    <ConfigProvider
      theme={theme}
      locale={ja_JP}
      form={{
        validateMessages: {
          types: {
            email: '有効なメールアドレスを入力してください'
          }
        }
      }}
    >
      <AppProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <ProfileProvider>
                <AppRouter />
              </ProfileProvider>
            </Provider>
          </QueryClientProvider>
        </BrowserRouter>
      </AppProvider>
    </ConfigProvider>
  );
};

export default App;
