import {
  App,
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  PhoneInput
} from 'core-ui';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LogoutIcon from 'src/assets/icons/logout';
import MobileContentContainer from 'src/components/mobile-content-container';
import MobileFooterContainer from 'src/components/mobile-footer-container';
import MobileFooterNavigation from 'src/components/mobile-footer-navigation';
import MobileHeader from 'src/components/mobile-header';
import { DATE_FORMAT } from 'src/constants/date';
import { notifyMessage } from 'src/constants/message';
import { systemColor } from 'src/theme';
import { apiRequest } from 'src/utils/api';
import { formDatePickerProps } from 'src/utils/form-props';

export default function ProfilePage() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const { data } = await apiRequest.get({
        url: '/v1/auth/me'
      });
      form.setFieldsValue(data.data);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
  };

  const handleSubmit = async () => {
    try {
      await apiRequest.post({
        url: '/v1/auth/update-profile',
        data: form.getFieldsValue()
      });
      message.success(notifyMessage.success);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <MobileHeader title="プロフィール" />
      <MobileContentContainer style={{ background: 'white' }}>
        <div style={{ height: '100%', padding: '16px' }}>
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item
              name="name"
              label="お名前"
              rules={[{ required: true, whitespace: true }]}
            >
              <Input placeholder="お名前" />
            </Form.Item>
            <Form.Item
              {...formDatePickerProps}
              rules={[{ required: true }]}
              name="dateOfBirth"
              label="生年月日"
            >
              <DatePicker format={DATE_FORMAT} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="address"
              label="住所"
              rules={[{ required: true, whitespace: true }]}
            >
              <Input placeholder="住所" />
            </Form.Item>
            <Form.Item
              name="email"
              label="メール"
              rules={[{ required: true, whitespace: true, type: 'email' }]}
            >
              <Input placeholder="メール" />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="電話番号"
              rules={[{ required: true, whitespace: true }]}
            >
              <PhoneInput placeholder="電話番号" />
            </Form.Item>
          </Form>
          <Link
            to="/change-password"
            style={{
              color: systemColor.link,
              fontSize: 16,
              borderBottom: `1px solid ${systemColor.link}`
            }}
          >
            パスワード変更
          </Link>
        </div>
        <Flex
          gap="0.5rem"
          style={{
            padding: '8px 16px',
            marginTop: 143,
            borderTop: '1px solid #AFAFAF26'
          }}
        >
          <Button size="large" block onClick={() => navigate(-1)}>
            キャンセル
          </Button>
          <Button size="large" block type="primary" onClick={form.submit}>
            保存
          </Button>
        </Flex>
      </MobileContentContainer>
      <MobileFooterContainer>
        <MobileFooterNavigation />
      </MobileFooterContainer>
    </div>
  );
}
