import { App, Button, Flex, Form, Input } from 'core-ui';
import MobileContentContainer from 'src/components/mobile-content-container';
import MobileHeader from 'src/components/mobile-header';
import { notifyMessage } from 'src/constants/message';
import { apiRequest } from 'src/utils/api';

export default function ChangePasswordPage() {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const handleSubmit = async () => {
    const formData = form.getFieldsValue();
    delete formData.confirmPassword;
    try {
      const { data } = await apiRequest.post({
        url: '/v1/auth/change-password',
        data: formData
      });
      message.success(notifyMessage.success);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
  };
  return (
    <div>
      <MobileHeader goBack title="パスワード変更" />
      <MobileContentContainer style={{ background: 'white' }}>
        <Form form={form} onFinish={handleSubmit}>
          <div style={{ padding: '24px 16px' }}>
            <Flex vertical align="center">
              <img
                src="/reset-password.png"
                alt="reset-password"
                style={{ width: 100, height: 100, marginBottom: 24 }}
              />
              <div
                style={{ fontWeight: 600, fontSize: 18, marginBottom: 26.5 }}
              >
                パスワード再設定
              </div>
            </Flex>
            <Form.Item
              rules={[{ required: true }]}
              label="以前のパスワード"
              name="oldPassword"
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
            <Form.Item
              label="新しいパスワード"
              rules={[{ required: true }]}
              name="newPassword"
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
            <Form.Item
              dependencies={['newPassword']}
              label="パスワード確認"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('入力したパスワードが一致しません')
                    );
                  }
                })
              ]}
              name="confirmPassword"
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
            <div style={{ marginBottom: 24 }}>
              【パスワードについて】
              <br />
              ・英大文字、英小文字、記号、数字のうち2種類以上を組み合わせて設定してください
              <br />
              ・8桁以上で設定してください
              <br />
              ・現在設定されているパスワードと同じパスワードは使えません
            </div>
            <Button type="primary" size="large" block htmlType="submit">
              パスワード変更
            </Button>
          </div>
        </Form>
      </MobileContentContainer>
    </div>
  );
}
