import { Flex } from 'core-ui';
import { useEffect, useState } from 'react';
import MobileContentContainer from 'src/components/mobile-content-container';
import MobileFooterContainer from 'src/components/mobile-footer-container';
import MobileFooterNavigation from 'src/components/mobile-footer-navigation';
import MobileHeader from 'src/components/mobile-header';
import ReceiptItem from 'src/components/receipt-item';
import { I_Receipt } from 'src/models';
import { systemColor } from 'src/theme';
import { apiRequest } from 'src/utils/api';

export default function ReceiptListPage() {
  const [dataSource, setDataSource] = useState<I_Receipt[]>([]);
  const fetchData = async () => {
    const { data } = await apiRequest.get({
      url: '/v1/receipts'
    });
    setDataSource(data.data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <MobileHeader title="レシート履歴" />
      <MobileContentContainer>
        <div style={{ padding: '1rem 0.5rem' }}>
          <Flex
            vertical
            gap={8}
            className="test"
            style={{
              borderRadius: 12,
              background: 'white',
              border: `1px solid ${systemColor.semiPrimary}`,
              padding: 8
            }}
          >
            {dataSource.map((i, idx) => (
              <ReceiptItem key={i.id} data={i} />
            ))}
          </Flex>
        </div>
      </MobileContentContainer>
      <MobileFooterContainer>
        <MobileFooterNavigation />
      </MobileFooterContainer>
    </div>
  );
}
