import { App, Button, Flex, Form, Input } from 'core-ui';
import { Link, useNavigate } from 'react-router-dom';
import LongLeftArrowIcon from 'src/assets/icons/long-left-arrow';
import { apiRequest } from 'src/utils/api';

export default function ForgotPasswordPage() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    try {
      const { data } = await apiRequest.post({
        url: '/v1/auth/forgot-password',
        data: form.getFieldsValue()
      });
      message.success('メールをご確認ください');
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
  };
  return (
    <Flex
      vertical
      align="center"
      justify="center"
      style={{ height: '100dvh', padding: 16 }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <div
          style={{
            fontWeight: 600,
            fontSize: 32,
            marginBottom: 24,
            textAlign: 'center'
          }}
        >
          パスワード再設定
        </div>
        <div style={{ background: '#F1F1F1', padding: 10, marginBottom: 16 }}>
          ご登録されているメールアドレスに案内メールを送信します。
        </div>
        <Form.Item
          label="メールアドレス"
          name="email"
          rules={[{ required: true, type: 'email', whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Button
          block
          type="primary"
          size="large"
          style={{ margin: '16px 0' }}
          htmlType="submit"
        >
          送信
        </Button>
        <Link to="/login">
          <Button block type="link" icon={<LongLeftArrowIcon />}>
            ログイン画面へ戻る
          </Button>
        </Link>
      </Form>
    </Flex>
  );
}
