import { ArrowLeftOutlined, Button, Flex } from 'core-ui';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { systemColor } from 'src/theme';

interface I_Props {
  children: ReactNode;
}
export default function MobileFooterContainer(props: I_Props) {
  return (
    <div
      style={{
        position: 'sticky',
        bottom: 0,
        width: '100%',
        background: 'white',
        boxShadow: '1px 0px 6.2px 0px #00000040'
      }}
    >
      {props.children}
    </div>
  );
}
