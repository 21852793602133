import { App, Button, Flex, Form, Input } from 'core-ui';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import LongLeftArrowIcon from 'src/assets/icons/long-left-arrow';
import { notifyMessage } from 'src/constants/message';
import { RoutePath } from 'src/enums/routePath';
import { apiRequest } from 'src/utils/api';

export default function ResetPasswordPage() {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const formData = form.getFieldsValue();
    delete formData.confirmPassword;
    formData.token = searchParams.get('token');
    try {
      const { data } = await apiRequest.post({
        url: '/v1/auth/reset-password',
        data: formData
      });
      message.success(notifyMessage.success);
      navigate(RoutePath.LOGIN);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
  };
  return (
    <Flex
      vertical
      align="center"
      justify="center"
      style={{ height: '100dvh', padding: 16 }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <div
          style={{
            fontWeight: 600,
            fontSize: 32,
            marginBottom: 24,
            textAlign: 'center'
          }}
        >
          パスワード再設定
        </div>
        <div style={{ background: '#F1F1F1', padding: 10, marginBottom: 16 }}>
          新しいパスワードを入力し、「パスワード再設定」ボタンを
          <br />
          押してください。
        </div>
        <Form.Item
          label="新しいパスワード"
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="パスワード確認"
          name="confirmPassword"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('入力したパスワードが一致しません')
                );
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Button
          block
          type="primary"
          size="large"
          style={{ margin: '16px 0' }}
          htmlType="submit"
        >
          パスワード再設定
        </Button>
        <Link to="/login">
          <Button block type="link" icon={<LongLeftArrowIcon />}>
            ログイン画面へ戻る
          </Button>
        </Link>
      </Form>
    </Flex>
  );
}
