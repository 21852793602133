import MainLayout from 'src/layouts/MainLayout';
import { lazy } from 'react';
import { RouteI } from 'src/models/router';
import { RoutePath } from 'src/enums/routePath';
import IndexPage from 'src/pages';
import ReceiptListPage from 'src/pages/receipts';
import Invoice from 'src/pages/receipts/invoice';
import ProfilePage from 'src/pages/profile';
import ChangePasswordPage from 'src/pages/change-password';
import LoginPage from 'src/pages/login';
import RegisterPage from 'src/pages/register';
import ForgotPasswordPage from 'src/pages/forgot-password';
import ResetPasswordPage from 'src/pages/reset-password';

export const routes: RouteI[] = [
  {
    path: '/',
    Component: IndexPage,
    isPrivate: true
  },
  {
    path: '/receipts',
    Component: ReceiptListPage,
    isPrivate: true
  },
  {
    path: '/receipts/:id',
    Component: Invoice,
    isPrivate: true
  },
  {
    path: '/profile',
    Component: ProfilePage,
    isPrivate: true
  },
  {
    path: '/change-password',
    Component: ChangePasswordPage,
    isPrivate: true
  },
  {
    path: '/login',
    Component: LoginPage
  },
  {
    path: '/register',
    Component: RegisterPage
  },
  {
    path: '/forgot-password',
    Component: ForgotPasswordPage
  },
  {
    path: '/reset-password',
    Component: ResetPasswordPage
  }
];
